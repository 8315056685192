<div class="modal is-active" *ngIf="showModal">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head pb-0">
      <p class="modal-card-title">
        Delete {{ itemToDelete[resourceDefinition.mainIdentifier] }} ?
      </p>
      <a
        class="is-flex is-align-item-center has-text-dark"
        aria-label="close"
        (click)="close()"
      >
        <i class="icon icon-x is-size-2"></i>
      </a>
    </header>
    <section class="modal-card-body is-size-5">
      <div class="columns">
        <div class="column">
          <p>
            Do you really want to delete
            <strong
              >{{ itemToDelete[resourceDefinition.mainIdentifier] }}
            </strong>
            ?
          </p>
          <p>This action cannot be undone.</p>
        </div>
      </div>
    </section>
    <footer
      class="modal-card-foot pt-0 is-flex is-justify-content-space-between"
    >
      <button class="button is-white" (click)="close()">Cancel</button>
      <button class="button is-link is-hidden-touch" (click)="confirmDelete()">
        Confirm delete
      </button>
      <button
        class="button is-link is-hidden-desktop"
        (click)="confirmDelete()"
      >
        <i class="icon-icon-trash"></i>
      </button>
    </footer>
  </div>
</div>
