<ng-container *ngIf="item">
  <!-- Main container -->
  <nav class="level">
    <!-- Left side -->
    <div class="level-left">
      <div class="level-item">
        <h1 class="title is-2 has-text-weight-light" [title]="item.name">
          {{ item.title }}
        </h1>
      </div>
    </div>

    <!-- Right side -->
    <div class="level-right">
      <p class="level-item">
        <a class="button is-danger is-outlined" [caseAction]="deleteAction">
          <span class="icon is-small">
            <i aria-hidden="true" class="icon icon-delete"></i>
          </span>
          <span>Delete</span>
        </a>
      </p>
      <p class="level-item">
        <a
          class="button is-link"
          [routerLink]="['/policy-initiatives/' + item.id + '/edit']"
        >
          <span class="icon is-small">
            <i aria-hidden="true" class="icon icon-edit"></i>
          </span>
          <span> Edit </span>
        </a>
      </p>
    </div>
  </nav>

  <div class="columns is-multiline">
    <div class="column is-8">
      <div class="card">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Name in original language
              </p>
              <p class="is-size-6">{{ item.name }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Responsible Organisation
              </p>
              <p class="is-size-6">{{ item.responsibleOrganisation }}</p>
            </div>

            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Category
              </p>
              <p class="is-size-6">{{ item.category }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Extent Binding
              </p>
              <p class="is-size-6">{{ item.extentBinding }}</p>
            </div>

            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Joint
              </p>
              <p class="is-size-6">{{ item.joint }}</p>
            </div>

            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Start Date
              </p>
              <p class="is-size-6">{{ item.startDate | date }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                End Date
              </p>
              <p class="is-size-6">{{ item.endDate | date }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Overview
              </p>
              <p class="is-size-6">{{ item.overview }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Budget
              </p>
              <p class="is-size-6">{{ item.budget }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Evaluation Status
              </p>
              <p class="is-size-6">{{ item.evaluationStatus }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Evaluation Type
              </p>
              <p class="is-size-6">{{ item.evaluationType }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Evaluation Results Is Publicly Available
              </p>
              <p class="is-size-6">
                {{ item.evaluationResultsIsPubliclyAvailable }}
              </p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Evaluation Description
              </p>
              <p class="is-size-6">{{ item.evaluationDescription }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Evaluation URLs
              </p>
              <p class="is-size-6">{{ item.evaluationUrls }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Evaluation Files
              </p>
              <p class="is-size-6">{{ item.evaluationFiles }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Relevant Files
              </p>
              <p class="is-size-6">{{ item.relevantFiles }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Video URL
              </p>
              <p class="is-size-6">{{ item.videoUrl }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                More Infos
              </p>
              <p class="is-size-6">{{ item.moreInfos }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Action Plan
              </p>
              <p class="is-size-6">{{ item.actionPlan }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Engagement Description
              </p>
              <p class="is-size-6">{{ item.engagementDescription }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Monitoring Mechanism
              </p>
              <p class="is-size-6">{{ item.monitoringMechanism }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Monitoring Description
              </p>
              <p class="is-size-6">{{ item.monitoringDescription }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <ng-container *ngFor="let taxonomy of policyInitiativeRelations">
              <h4
                class="is-uppercase has-text-dark has-text-weight-medium mt-1 is-size-6"
              >
                {{ taxonomy.label }}
              </h4>
              <ng-container *ngIf="!item[taxonomy.propName].length">
                <em>-</em></ng-container
              >
              <ng-container *ngIf="item[taxonomy.propName].length">
                <ul>
                  <li *ngFor="let relation of item[taxonomy.propName]">
                    <span class="is-size-6">{{ relation.name }}</span>
                  </li>
                </ul></ng-container
              >
              <hr />
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
