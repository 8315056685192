import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { metricDefinition } from '../metric.definition'
import { metricCreateEditFields } from './metric-create-edit.fields'
import { caseCreateEditTemplate } from '../../../abacus/templates/case-create-edit.template'
import { CaseCreateEditComponent } from '../../../abacus/components/case-create-edit.component'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { Field } from '../../../abacus/interfaces/field.interface'
import { ResourceService } from '../../../abacus/services/resource.service'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'

@Component({ template: caseCreateEditTemplate })
export class MetricCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = metricDefinition
  fields: Field[] = metricCreateEditFields

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
