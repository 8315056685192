<ng-container *ngIf="item">
  <!-- Main container -->
  <nav class="level">
    <!-- Left side -->
    <div class="level-left">
      <div class="level-item">
        <h1 class="title is-2 has-text-weight-light" [title]="item.name">
          {{ item.name | truncate : ['70'] }}
        </h1>
        <span class="tag is-warning ml-2" *ngIf="item.status === Status.Pending"
          >Pending</span
        >
        <span class="tag is-danger ml-2" *ngIf="item.status === Status.Rejected"
          >Rejected</span
        >
        <span
          class="tag is-success ml-2"
          *ngIf="item.status === Status.Published"
          >Published since
          <strong class="has-text-light ml-2">{{
            item.publishedAt | date
          }}</strong></span
        >
      </div>
    </div>

    <!-- Right side -->
    <div class="level-right">
      <p class="level-item">
        <a class="button is-danger is-outlined" [caseAction]="deleteAction">
          <span class="icon is-small">
            <i aria-hidden="true" class="icon icon-delete"></i>
          </span>
          <span>Delete</span>
        </a>
      </p>
      <p class="level-item">
        <a
          class="button is-link"
          [routerLink]="['/metrics/' + item.id + '/edit']"
        >
          <span class="icon is-small">
            <i aria-hidden="true" class="icon icon-edit"></i>
          </span>
          <span> Edit </span>
        </a>
      </p>
      <p class="level-item">
        <a
          *ngIf="item.status === Status.Pending"
          class="button is-success"
          [caseAction]="publishAction"
        >
          <span class="icon is-small">
            <i aria-hidden="true" class="icon icon-file-text"></i>
          </span>
          <span>Publish</span>
        </a>
        <a
          *ngIf="
            item.status === Status.Published || item.status === Status.Rejected
          "
          class="button is-warning"
          [caseAction]="pendingAction"
          >Switch to pending</a
        >
      </p>
      <p class="level-item" *ngIf="item.status === Status.Pending">
        <a
          class="button is-danger"
          [caseAction]="rejectAction"
        >
          <span class="icon is-small">
            <i aria-hidden="true" class="icon icon-file-text"></i>
          </span>
          <span>Reject</span>
        </a>
      </p>
    </div>
  </nav>

  <app-change-history
    [item]="item"
    [entityType]="Entity.Metric"
    [itemRelations]="itemRelations"
    [ngClass]="{ 'is-block mb-4': item.status === Status.Rejected }"
  ></app-change-history>

  <article class="message is-danger" *ngIf="item.status === Status.Rejected">
    <div class="message-body has-background-dark has-text-light">
      <p><strong>This metric has been rejected</strong></p>

      <p *ngIf="item.rejectReason">
        <i class="icon icon-file-text mr-2"></i>
        <span>{{ item.rejectReason }}</span>
      </p>
    </div>
  </article>
  <div class="columns is-multiline">
    <div class="column is-8">
      <div class="card">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Description
              </p>
              <div class="content">
                <div class="is-size-6" [innerHTML]="item.content"></div>
              </div>
            </div>
          </div>
          <hr />
          <div class="columns is-multiline">
            <div class="column is-12">
              <table class="table is-narrow">
                <tbody>
                  <tr>
                    <td
                      class="pl-0 has-text-grey-darker has-text-weight-medium is-size-6"
                    >
                      Website:
                    </td>
                    <td>
                      <a
                        href="{{ item.websiteLink }}"
                        class="has-text-link is-underlined"
                        target="_blank"
                        >{{ item.websiteLink }}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="pl-0 has-text-grey-darker has-text-weight-medium is-size-6"
                    >
                      Github:
                    </td>
                    <td>
                      <a
                        href="{{ item.githubLink }}"
                        class="has-text-link is-underlined"
                        target="_blank"
                      >
                        {{ item.githubLink }}
                      </a>
                      <div class="tags">
                        <span class="tag ml-2"
                          ><i class="icon icon-star mr-1"></i>Stars :
                          {{ item.githubStars }}</span
                        >
                        <span class="tag ml-2"
                          ><i class="icon icon-branch mr-1"></i>Forks:
                          {{ item.githubForks }}</span
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="pl-0 has-text-grey-darker has-text-weight-medium is-size-6"
                    >
                      Citations:
                    </td>
                    <td>
                      <span class="has-text-link is-underlined"
                        >{{ item.citations }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                References
              </p>
              <div class="is-size-6" [innerHTML]="item.content"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card mt-6"
        *ngIf="item.objectives?.length || item.organisations?.length"
      >
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-12">
              <div class="content">
                <ng-container *ngFor="let metricRelation of metricRelations">
                  <ng-container
                    *ngIf="
                      metricRelation.propName === 'objectives' &&
                      item[metricRelation.propName]?.length
                    "
                  >
                    <h4
                      class="is-uppercase has-text-dark has-text-weight-medium mt-1 is-size-6"
                    >
                      {{ metricRelation.label }}
                    </h4>
                    <ul>
                      <li
                        *ngFor="let relation of item[metricRelation.propName]"
                      >
                        <span class="is-size-6">{{ relation.name }}</span>
                      </li>
                    </ul>

                    <hr />
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-6">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-6">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Related Metric Use Cases
              </p>
              <table class="table is-narrow is-fullwidth">
                <tbody>
                  <some-element [ngPlural]="item.metricUseCases?.length">
                    <ng-template ngPluralCase="=0"> - </ng-template>
                    <ng-template ngPluralCase="other">
                      <tr *ngFor="let muc of item.metricUseCases">
                        <td class="pl-0 is-size-6">
                          <a
                            class="has-text-link is-underlined"
                            [routerLink]="['/metric-use-cases', muc.id]"
                            >{{ muc.name }}</a
                          >
                        </td>
                      </tr></ng-template
                    >
                  </some-element>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <ng-container *ngFor="let metricRelation of metricRelations">
              <ng-container
                *ngIf="
                  metricRelation.propName !== 'objectives' &&
                  metricRelation.propName !== 'organisations'
                "
              >
                <h4
                  class="is-uppercase has-text-dark has-text-weight-medium mt-1 is-size-6"
                >
                  {{ metricRelation.label }}
                </h4>
                <ng-container *ngIf="!item[metricRelation.propName].length">
                  <em>-</em></ng-container
                >
                <ng-container *ngIf="item[metricRelation.propName].length">
                  <ul>
                    <li *ngFor="let relation of item[metricRelation.propName]">
                      <span class="is-size-6">{{ relation.name }}</span>
                    </li>
                  </ul></ng-container
                >
                <hr />
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="card mt-6">
        <div class="card-content">
          <div class="content">
            <h4
              class="is-uppercase has-text-dark has-text-weight-medium mt-1 is-size-6"
            >
              Submitter
            </h4>

            <div>
              <div class="is-flex is-align-items-center mb-2">
                <!-- TODO:Put a default image -->
                <!-- <div class="avatar avatar-sm">
                  <img
                    src="https://picsum.photos/id/177/64/64"
                    title="Romain Caldara"
                    alt="image"
                  />
                </div> -->
                <div
                  class="is-flex is-flex-direction-column is-align-items-flex-start pl-2"
                >
                  <span>{{ item.submitter }}</span
                  ><strong>{{ item.email }}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
