<ng-container *ngIf="item">
  <!-- Main container -->
  <nav class="level">
    <!-- Left side -->
    <div class="level-left">
      <div class="level-item">
        <h1 class="title is-2 has-text-weight-light" [title]="item.name">
          {{ item.name | truncate : ['70'] }}
        </h1>
        <span class="tag is-warning ml-2" *ngIf="item.status === Status.Pending"
          >Pending</span
        >
        <span class="tag is-danger ml-2" *ngIf="item.status === Status.Rejected"
          >Rejected</span
        >
        <span
          class="tag is-success ml-2"
          *ngIf="item.status === Status.Published"
          >Published since
          <strong class="has-text-light ml-2">{{
            item.publishedAt | date
          }}</strong></span
        >
      </div>
    </div>

    <!-- Right side -->
    <div class="level-right">
      <p class="level-item">
        <a class="button is-danger is-outlined" [caseAction]="deleteAction">
          <span class="icon is-small">
            <i aria-hidden="true" class="icon icon-delete"></i>
          </span>
          <span>Delete</span>
        </a>
      </p>
      <p class="level-item">
        <a
          class="button is-link"
          [routerLink]="['/tool-use-cases/' + item.id + '/edit']"
        >
          <span class="icon is-small">
            <i aria-hidden="true" class="icon icon-edit"></i>
          </span>
          <span> Edit </span>
        </a>
      </p>
      <p class="level-item">
        <a
          *ngIf="item.status === Status.Pending"
          class="button is-success"
          [caseAction]="publishAction"
        >
          <span class="icon is-small">
            <i aria-hidden="true" class="icon icon-file-text"></i>
          </span>
          <span>Publish</span>
        </a>
        <a
          *ngIf="
            item.status === Status.Published || item.status === Status.Rejected
          "
          class="button is-warning"
          [caseAction]="pendingAction"
          >Switch to pending</a
        >
      </p>
      <p class="level-item" *ngIf="item.status === Status.Pending">
        <a
          class="button is-danger"
          [caseAction]="rejectAction"
        >
          <span class="icon is-small">
            <i aria-hidden="true" class="icon icon-file-text"></i>
          </span>
          <span>Reject</span>
        </a>
      </p>
    </div>
  </nav>

  <app-change-history
    [item]="item"
    [entityType]="Entity.ToolUseCase"
    [itemRelations]="itemRelations"
    [ngClass]="{ 'is-block mb-4': item.status === Status.Rejected }"
  ></app-change-history>

  <article class="message is-danger" *ngIf="item.status === Status.Rejected">
    <div class="message-body has-background-dark has-text-light">
      <p><strong>This tool use case has been rejected</strong></p>

      <p *ngIf="item.rejectReason">
        <i class="icon icon-file-text mr-2"></i>
        <span>{{ item.rejectReason }}</span>
      </p>
    </div>
  </article>
  <div class="columns is-multiline">
    <div class="column is-8">
      <div class="card">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-12">
              <article *ngIf="item.tool" class="message is-warning">
                <div class="message-body">
                  <p class="is-uppercase has-text-weight-medium is-size-6">
                    This use case is related to the following tool:
                  </p>
                  <p>
                    <a [routerLink]="['/tools', item.tool.id]">{{
                      item.tool.name
                    }}</a>
                  </p>
                </div>
              </article>
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Short sentence
              </p>
              <p class="is-size-6">{{ item.shortSentence }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Description
              </p>
              <div class="content">
                <div class="is-size-6" [innerHTML]="item.content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-6">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-12">
              <div class="content">
                <p
                  class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
                >
                  Benefits
                </p>
                <p *ngIf="!item.benefits" class="is-size-6"><em> - </em></p>

                <div class="content" *ngIf="item.benefits">
                  <div class="is-size-6" [innerHTML]="item.benefits"></div>
                </div>

                <p
                  class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
                >
                  Shortcomings
                </p>

                <p *ngIf="!item.shortcomings" class="is-size-6"><em> - </em></p>
                <div class="content" *ngIf="item.shortcomings">
                  <div class="is-size-6" [innerHTML]="item.shortcomings"></div>
                </div>
                <p
                  class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
                >
                  Learnings
                </p>

                <p *ngIf="!item.learnings" class="is-size-6"><em> - </em></p>
                <div class="content" *ngIf="item.learnings">
                  <div class="is-size-6" [innerHTML]="item.learnings"></div>
                </div>
                <p
                  class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
                >
                  Comparison
                </p>
                <p class="is-size-6" *ngIf="!item.comparison">-</p>
                <div class="content" *ngIf="item.comparison">
                  <div class="is-size-6" [innerHTML]="item.comparison"></div>
                </div>
                <hr />

                <ng-container *ngFor="let tucRelation of tucRelations">
                  <ng-container
                    *ngIf="
                      tucRelation.propName === 'objectives' ||
                      tucRelation.propName === 'organisations'
                    "
                  >
                    <h4
                      class="is-uppercase has-text-dark has-text-weight-medium mt-1 is-size-6"
                    >
                      {{ tucRelation.label }}
                    </h4>

                    <ng-container *ngIf="!item[tucRelation.propName].length">
                      <em>-</em></ng-container
                    >
                    <ng-container *ngIf="item[tucRelation.propName].length">
                      <ul>
                        <li *ngFor="let relation of item[tucRelation.propName]">
                          <span class="is-size-6">{{ relation.name }}</span>
                        </li>
                      </ul></ng-container
                    >
                    <hr />
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <ng-container *ngFor="let tucRelation of tucRelations">
              <ng-container
                *ngIf="
                  tucRelation.propName !== 'objectives' &&
                  tucRelation.propName !== 'organisations'
                "
              >
                <h4
                  class="is-uppercase has-text-dark has-text-weight-medium mt-1 is-size-6"
                >
                  {{ tucRelation.label }}
                </h4>
                <ng-container *ngIf="!item[tucRelation.propName].length">
                  <em>-</em></ng-container
                >
                <ng-container *ngIf="item[tucRelation.propName].length">
                  <ul>
                    <li *ngFor="let relation of item[tucRelation.propName]">
                      <span class="is-size-6">{{ relation.name }}</span>
                    </li>
                  </ul></ng-container
                >
                <hr />
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <ng-container *ngIf="item.image">
        <div class="card mt-6">
        <div class="card-content">
          <h4
            class="is-uppercase has-text-dark has-text-weight-medium mt-1 is-size-6"
          >
            Image
          </h4>
          <div class="card-image">
            <case-image [path]="item.image"></case-image>
          </div>
        </div>
      </div>
      </ng-container>
      <div class="card mt-6">
        <div class="card-content">
          <div class="content">
            <h4
              class="is-uppercase has-text-dark has-text-weight-medium mt-1 is-size-6"
            >
              Submitter
            </h4>

            <div>
              <div class="is-flex is-align-items-center mb-2">
                <div class="avatar avatar-sm">
                  <img
                    src="https://picsum.photos/id/177/64/64"
                    title="Image"
                    alt="image"
                  />
                </div>
                <div
                  class="is-flex is-flex-direction-column is-align-items-flex-start pl-2"
                >
                  <span>{{ item.submitter }}</span
                  ><strong>{{ item.email }}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
