
<ng-container *ngIf="records?.length">
  <div class="columns">
    <div class="column is-12 has-text-right"> 
      <a
        [ngPlural]="records.length"
        class="py-6"
        (click)="showRecordList = !showRecordList"
      >
        <ng-template ngPluralCase="=1"><span> 1 change </span></ng-template>
        <ng-template ngPluralCase="other">
          <span>{{ records.length }} changes</span></ng-template
        >
      </a>
    </div>
  </div>
</ng-container>

<div class="modal is-right has-text-left"
[ngClass]="{ 'is-active': showRecordList }"
>
<div class="modal-background" (click)="showRecordList = false"></div>

<div class="modal-card is-right">     
  <div class="modal-card-body is-fullheight">
    <div class="content">
          <!-- List of records -->
          <ng-container *ngIf="!detailedRecord">
            <h3 class="title is-5 mb-6 icon-text is-align-items-center">
                    
              <span class="icon pointer is-large" (click)="showRecordList = false">
                <i class="icon icon-chevron-left is-size-3"></i>
              </span>
              <span>History of changes</span>
            </h3>
            <p *ngFor="let record of records">
              
                <a
                  class="has-text-link is-underlined"
                  (click)="detailedRecord = showRecord(record, itemRelations)"
                >
                  {{ record.createdAt | date : "dd/mm/yyyy 'at' hh:mm" }}
                </a>
            </p>
          </ng-container>

          <!-- Record detail -->
          <ng-container *ngIf="detailedRecord">
            
              <h3 class="title is-5 mb-6 icon-text is-align-items-center">
                    
                <span class="icon pointer is-large" (click)="detailedRecord = null">
                  <i class="icon icon-chevron-left is-size-3"></i>
                </span>
                <span>Changes on
                  {{ detailedRecord.date | date : "dd/mm/yyyy 'at' hh:mm" }}</span>
              </h3>
            

            <table class="table is-narrow is-fullwidth is-striped is-bordered ">
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Before</th>
                  <th>After</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let change of detailedRecord.fieldChanges">
                  <th>{{ change.key }}</th>
                  <td>{{ change.valueBefore || '(empty)' }}</td>
                  <td>{{ change.valueAfter || '(empty)' }}</td>
                </tr>
              </tbody>
            </table>
          </ng-container>
  </div>
  </div>
  </div>
  </div>

