<label for="image-input-{{ uniqueId }}" class="full-width">
  {{ label }}
  <span class="has-text-danger" *ngIf="required">*</span>
</label>

<div class="columns is-multiline is-align-items-flex-end">
  <div
    class="column is-12-mobile is-6-tablet is-3-desktop"
    *ngFor="let option of selectOptions"
    (click)="select(option)"
  >
    <div class="control">
      <label
        class="radio is-boxed radio-label mt-0"
        [ngClass]="{
          'is-selected': option.selected,
          'is-disabled': option.disabled
        }"
      >
        <span class="radio-cta">
          <span
            class="radio-label has-text-black"
            [ngClass]="{
              'has-text-grey': option.disabled
            }"
          >
            {{ option.label }}
          </span>
          <span class="radio-sub-label has-text-grey">
            {{ option.subLabel }}
          </span>
        </span>
      </label>
    </div>
  </div>
</div>
