import { YieldType } from '../../abacus/enums/yield-type.enum'
import { Yield } from '../../abacus/interfaces/yield.interface'

export const policyInitiativeYields: Yield[] = [
  {
    label: 'Title',
    property: 'title'
  },
  {
    label: 'Country',
    property: 'nationalOrganisationNames'
  },
  {
    label: 'Organisation',
    property: 'intergovernmentalOrganisationNames'
  },
  {
    label: 'Created On',
    property: 'createdAt',
    type: YieldType.Date
  },
  {
    label: 'Status',
    property: 'status'
  },
  {
    label: 'Types',
    property: 'typeNames'
  },
  {
    label: 'Contact (last person to update)',
    property: 'lastUpdatedByName'
  }
]
