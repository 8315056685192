import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { InputType } from 'src/app/abacus/enums/input-type.enum'
import { EngagementMechanism } from '../../../../../../shared/enums/policy-initiative/engagement-mechanism.enum'
import { EvaluationStatus } from '../../../../../../shared/enums/policy-initiative/evaluation-status.enum'
import { EvaluationType } from '../../../../../../shared/enums/policy-initiative/evaluation-type.enum'
import { ExtentBinding } from '../../../../../../shared/enums/policy-initiative/extent-binding.enum'
import { InitiativeCategory } from '../../../../../../shared/enums/policy-initiative/initiative-category.enum'
import { InitiativeStatus } from '../../../../../../shared/enums/policy-initiative/initiative-status.enum'
import { CaseCreateEditComponent } from '../../../abacus/components/case-create-edit.component'
import { Field } from '../../../abacus/interfaces/field.interface'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { caseCreateEditTemplate } from '../../../abacus/templates/case-create-edit.template'
import { aiPrincipleDefinition } from '../../ai-principle/ai-principle.definition'
import { aiTagDefinition } from '../../ai-tag/ai-tag.definition'
import { initiativeTypeDefinition } from '../../initiative-type/initiative-type.definition'
import { intergovernmentalOrganisationDefinition } from '../../intergovernmental-organisation/intergovernmental-organisation.definition'
import { nationalOrganisationDefinition } from '../../national-organisation/national-organisation.definition'
import { targetSectorDefinition } from '../../target-sector/target-sector.definition'
import { policyInitiativeDefinition } from '../policy-initiative.definition'

@Component({ template: caseCreateEditTemplate })
export class PolicyInitiativeCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = policyInitiativeDefinition
  fields: Field[] = [
    {
      label: 'Original Name',
      placeholder: 'Enter the policy initiative name in original language ...',
      property: 'name',
      className: 'is-6',
      inputType: InputType.Text
    },
    {
      label: 'English Name',
      placeholder: 'Enter the policy initiative in the english name...',
      property: 'title',
      className: 'is-6',
      inputType: InputType.Text,
      required: true
    },
    {
      label: 'Description',
      placeholder: 'Enter the description...',
      property: 'description',
      className: 'is-12',
      inputType: InputType.Text,
      required: true
    },

    {
      label: 'Website',
      placeholder: 'Enter the website...',
      property: 'website',
      className: 'is-6',
      inputType: InputType.Text,
      required: true
    },
    {
      label: 'Status',
      property: 'status',
      placeholder: 'Chose the status...',
      required: true,
      inputType: InputType.Select,
      selectOptions: [
        {
          value: InitiativeStatus.ProposedUnder,
          label: 'Proposed or under development'
        },
        { value: InitiativeStatus.Active, label: 'Active' },
        {
          value: InitiativeStatus.Complete,
          label: 'Inactive – initiative complete'
        },
        {
          value: InitiativeStatus.InitiativeNotInitiated,
          label: 'Inactive – initiative not initiated'
        }
      ]
    },
    {
      label: 'Responsible Organisation',
      placeholder: 'Enter the responsible organisation...',
      property: 'responsibleOrganisation',
      className: 'is-6',
      inputType: InputType.Text,
      required: true
    },
    {
      label: 'Category',
      placeholder: 'Chose the category...',
      property: 'category',
      required: true,
      inputType: InputType.Select,
      selectOptions: [
        {
          value: InitiativeCategory.Intergovernmental,
          label: 'Intergovernmental or supranational'
        },
        {
          value: InitiativeCategory.NationalStrategy,
          label: 'National – Strategy'
        },
        {
          value: InitiativeCategory.NationalGovernance,
          label: 'National – AI governance bodies or mechanisms'
        },
        {
          value: InitiativeCategory.NationalGeneral,
          label:
            'National – AI policy initiatives, regulations, guidelines, standards and programmes or projects'
        }
      ]
    },
    {
      label: 'Extent Binding',
      placeholder: 'Chose the extent binding...',
      property: 'extentBinding',
      required: true,
      inputType: InputType.Select,
      selectOptions: [
        { value: ExtentBinding.Binding, label: 'Binding' },
        { value: ExtentBinding.NonBinding, label: 'Non-binding' }
      ]
    },
    {
      label: 'Joint',
      placeholder: 'Is it a joint initiative...',
      property: 'joint',
      required: true,
      initialValue: { value: false },
      inputType: InputType.Checkbox
    },

    {
      label: 'Start Date',
      placeholder: 'Enter the start date...',
      property: 'startDate',
      className: 'is-6',
      inputType: InputType.Datepicker
    },
    {
      label: 'End Date',
      placeholder: 'Enter the end date...',
      property: 'endDate',
      className: 'is-6',
      inputType: InputType.Datepicker,
      helpText: 'The Policy Initiative will be active until this date.'
    },
    {
      label: 'Overview',
      placeholder: 'Enter the overview...',
      property: 'overview',
      className: 'is-12',
      inputType: InputType.Text
    },
    {
      label: 'Budget',
      placeholder: 'Enter the budget...',
      property: 'budget',
      className: 'is-6',
      inputType: InputType.Number
    },
    {
      label: 'Evaluation Status',
      placeholder: 'Chose the evaluation status...',
      property: 'evaluationStatus',
      required: true,
      inputType: InputType.Select,
      selectOptions: [
        { value: EvaluationStatus.Yes, label: 'Yes' },
        {
          value: EvaluationStatus.NotYet,
          label: 'Not yet, but an evaluation is foreseen'
        },
        { value: EvaluationStatus.No, label: 'No' },
        { value: EvaluationStatus.DontKnow, label: 'Don’t know' }
      ]
    },
    {
      label: 'Evaluation Type',
      placeholder: 'Chose the evaluation type...',
      property: 'evaluationType',
      required: true,
      inputType: InputType.Select,
      selectOptions: [
        { value: EvaluationType.InHouse, label: 'In-house' },
        { value: EvaluationType.Independently, label: 'Independently' }
      ]
    },
    {
      label: 'Evaluation Results Publicly Available',
      placeholder: 'Are the evaluation results publicly available...',
      property: 'evaluationResultsIsPubliclyAvailable',
      required: true,
      initialValue: { value: false },
      inputType: InputType.Checkbox
    },
    {
      label: 'Evaluation Description',
      placeholder: 'Enter the evaluation description...',
      property: 'evaluationDescription',
      className: 'is-12',
      inputType: InputType.RichText
    },
    {
      label: 'Evaluation URLs',
      placeholder: 'Enter the evaluation URLs...',
      property: 'evaluationUrls',
      className: 'is-6',
      inputType: InputType.Text
    },
    {
      label: 'Video URL',
      placeholder: 'Enter the video URL...',
      property: 'videoUrl',
      className: 'is-6',
      inputType: InputType.Text
    },
    {
      label: 'More Infos',
      placeholder: 'Enter the more infos about the policy initiative...',
      property: 'moreInfos',
      className: 'is-12',
      inputType: InputType.RichText
    },
    {
      label: 'Action Plan',
      placeholder: 'Enter the action plan...',
      property: 'actionPlan',
      className: 'is-12',
      inputType: InputType.RichText
    },

    {
      label: 'Target Sectors',
      properties: {
        targetSectorIds: 'targetSectorIds'
      },
      className: 'is-6',
      searchResources: [targetSectorDefinition],
      inputType: InputType.MultiSearch,
      showCreateButton: true
    },

    {
      label: 'Monitoring Mechanism',
      placeholder: 'Enter the monitoring mechanism...',
      property: 'monitoringMechanism',
      className: 'is-6',
      initialValue: { value: false },
      inputType: InputType.Checkbox
    },
    {
      label: 'Monitoring Description',
      placeholder: 'Enter the monitoring description...',
      property: 'monitoringDescription',
      className: 'is-12',
      inputType: InputType.RichText
    },

    // Relationship fields.

    {
      label: 'Intergovernmental Organisations',
      properties: {
        intergovernmentalOrganisationIds: 'intergovernmentalOrganisationIds'
      },
      retrievedItemProperties: {
        intergovernmentalOrganisationIds: 'intergovernmentalOrganisationIds'
      },
      className: 'is-6',
      searchResources: [intergovernmentalOrganisationDefinition],
      inputType: InputType.MultiSearch
    },

    {
      label: 'Initiative Type',
      properties: {
        initiativeTypeIds: 'initiativeTypeIds'
      },
      retrievedItemProperties: {
        initiativeTypeIds: 'initiativeTypeIds'
      },
      className: 'is-6',
      searchResources: [initiativeTypeDefinition],
      inputType: InputType.MultiSearch
    },

    {
      label: 'Engagement Mechanisms',
      placeholder: 'Chose the evaluation type...',
      property: 'engagementMechanism',
      inputType: InputType.Select,
      selectOptions: [
        {
          value: EngagementMechanism.StakeholderConsultation,
          label: EngagementMechanism.StakeholderConsultation
        },
        {
          value: EngagementMechanism.PrivateConsultation,
          label: EngagementMechanism.PrivateConsultation
        },
        {
          value: EngagementMechanism.PublicConsultation,
          label: EngagementMechanism.PublicConsultation
        },
        {
          value: EngagementMechanism.NotApplicable,
          label: EngagementMechanism.NotApplicable
        },
        {
          value: EngagementMechanism.DontKnow,
          label: EngagementMechanism.DontKnow
        },
        { value: EngagementMechanism.Other, label: EngagementMechanism.Other }
      ]
    },

    {
      label: 'AI Principles',
      properties: {
        AIPrincipleIds: 'AIPrincipleIds'
      },
      retrievedItemProperties: {
        AIPrincipleIds: 'AIPrincipleIds'
      },
      className: 'is-6',
      searchResources: [aiPrincipleDefinition],
      inputType: InputType.MultiSearch
    },

    {
      label: 'AI Tags',
      properties: {
        AITagIds: 'AITagIds'
      },
      retrievedItemProperties: {
        AITagIds: 'AITagIds'
      },
      className: 'is-6',
      searchResources: [aiTagDefinition],
      inputType: InputType.MultiSearch
    },

    {
      label: 'Countries',
      properties: {
        nationalOrganisationIds: 'nationalOrganisationIds'
      },
      retrievedItemProperties: {
        nationalOrganisationIds: 'nationalOrganisationIds'
      },
      className: 'is-6',
      searchResources: [nationalOrganisationDefinition],
      inputType: InputType.MultiSearch
    }
  ]

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private customResourceService: ResourceService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
