<nav class="navbar" role="navigation" aria-label="main navigation" #menu>
  <div
    class="navbar-wrapper is-flex is-align-items-center is-justify-content-space-between is-flex-direction-row-reverse is-fullheight"
  >
    <div
      class="dropdown is-right"
      [ngClass]="{ 'is-active': showUserMenu }"
      *ngIf="currentUser"
    >
      <div class="user-box">
        <a
          [routerLink]="['/users', currentUser.id, 'edit']"
          aria-haspopup="true"
          aria-controls="dropdown-menu2"
        >
          <case-image
            [path]="currentUser.image"
            replacement="/assets/images/avatar.svg"
            [title]="currentUser.name"
            [alt]="currentUser.name"
            className="avatar"
          >
          </case-image>
        </a>
      </div>
    </div>
    <a
      class="logo is-flex is-align-items-center is-justify-content-center px-0"
      routerLink="/"
      (click)="closeMenu()"
    >
      <img src="/assets/images/logo.svg" width="84" height="32" />
    </a>

    <a
      role="button"
      class="navbar-burger burger ml-0"
      aria-label="menu"
      aria-expanded="false"
      data-target="navbarBasicExample"
      (click)="toggleMenu()"
    >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>
  <div class="hiding-layer" #hidingLayer></div>
  <div class="navbar-menu" id="create-dropdown" #menuItemsEl>
    <div class="navbar-end">
      <ul class="menu-list mt-5 menu-list--top">
        <ng-container *ngFor="let menuItem of menuItems; let i = index">
          <li *caseHasPermission="{ requireOr: menuItem.permissionsOr }">
            <!-- Direct link -->
            <ng-container *ngIf="menuItem.routePath">
              <a [routerLink]="menuItem.routePath" routerLinkActive="is-active">
                <i class="icon mr-3 is-size-4" [ngClass]="menuItem.icon"></i>
                <span class="pl-1">{{ menuItem.label }}</span>
              </a>
            </ng-container>

            <!-- Sub list -->
            <ng-container *ngIf="menuItem.items && menuItem.items.length">
              <a
                (click)="toggleAccordion('accordion-' + i)"
                class="has-submenu"
              >
                <span>
                  <i class="icon mr-3 is-size-4" [ngClass]="menuItem.icon"></i>
                  <span class="pl-1">{{ menuItem.label }}</span>
                </span>
                <i
                  class="icon icon-chevron-down mr-1"
                  *ngIf="activeAccordion !== 'accordion-' + i"
                ></i>
                <i
                  class="icon icon-chevron-up mr-1"
                  *ngIf="activeAccordion === 'accordion-' + i"
                ></i>
              </a>

              <ul *ngIf="activeAccordion === 'accordion-' + i">
                <ng-container *ngFor="let subItem of menuItem.items">
                  <li *caseHasPermission="{ requireOr: subItem.permissionsOr }">
                    <a
                      [routerLink]="subItem.routePath"
                      routerLinkActive="is-active"
                    >
                      <span class="pl-1">{{ subItem.label }}</span>
                    </a>
                  </li>
                </ng-container>
              </ul>
            </ng-container>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</nav>
