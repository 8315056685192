<ul class="menu-list mt-5">
  <ng-container *ngFor="let menuItem of menuItems; let i = index">
    <li *caseHasPermission="{ requireOr: menuItem.permissionsOr }">
      <!-- Direct link -->
      <ng-container *ngIf="menuItem.routePath">
        <a [routerLink]="menuItem.routePath" routerLinkActive="is-active">
          <i class="icon mr-3 is-size-4 my-0" [ngClass]="menuItem.icon"></i>
          <span class="pl-1">{{ menuItem.label }}</span>
        </a>
      </ng-container>

      <!-- Sub list -->
      <ng-container *ngIf="menuItem.items && menuItem.items.length">
        <a (click)="toggleAccordion('accordion-' + i)" class="has-submenu">
          <span>
            <i class="icon mr-3 is-size-4 my-0" [ngClass]="menuItem.icon"></i>
            <span class="pl-1">{{ menuItem.label }}</span>
          </span>
          <i
            class="icon icon-chevron-down mr-1"
            *ngIf="activeAccordion !== 'accordion-' + i"
          ></i>
          <i
            class="icon icon-chevron-up mr-1"
            *ngIf="activeAccordion === 'accordion-' + i"
          ></i>
        </a>
        <ul *ngIf="activeAccordion === 'accordion-' + i">
          <ng-container *ngFor="let subItem of menuItem.items">
            <li *caseHasPermission="{ requireOr: subItem.permissionsOr }">
              <a [routerLink]="subItem.routePath" routerLinkActive="is-active">
                <span class="pl-1">{{ subItem.label }}</span>
              </a>
            </li>
          </ng-container>
        </ul>
      </ng-container>
    </li>
  </ng-container>
</ul>
