export const environment = {
  production: false,
  envName: 'staging',
  appName: '[STAGING] OECD.AI Admin Dashboard',
  baseUrl: 'https://pp.oecd-ai.case-dashboard.buddyweb.fr',
  apiBaseUrl: 'https://pp.oecd-ai.case-api.buddyweb.fr',
  storagePath: 'https://pp.oecd-ai.case-api.buddyweb.fr/storage',
  tokenName: 'oecdAITokenStaging',
  tokenAllowedDomains: ['pp.oecd-ai.case-api.buddyweb.fr'],
  enableBugsnag: true,
  bugsnagApiKey: 'c7f04b4b37cbe41d375491e8497c4509',
  isOnboarding: false
}
