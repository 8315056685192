<div class="welcome-wrapper">
  <img
    src="../../../../assets/images/error404.svg"
    alt="Error 404"
    width="360"
  />
  <h1 class="title is-1">Error 404: Page not found</h1>
  <p>Maybe this page is private or it does not exist anymore.</p>
  <p>
    <a routerLink="/" class="button is-link mt-5 mr-2">
      Go back to the homepage
    </a>
  </p>
</div>
