import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { caseCreateEditTemplate } from '../../../abacus/templates/case-create-edit.template'
import { CaseCreateEditComponent } from '../../../abacus/components/case-create-edit.component'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { Field } from '../../../abacus/interfaces/field.interface'
import { ResourceService } from '../../../abacus/services/resource.service'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'

import { toolUseCaseDefinition } from '../tool-use-case.definition'
import { toolUseCaseCreateEditFields } from './tool-use-case-create-edit.fields'

@Component({ template: caseCreateEditTemplate })
export class ToolUseCaseCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = toolUseCaseDefinition
  fields: Field[] = toolUseCaseCreateEditFields

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
